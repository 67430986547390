import React, { useContext, useEffect } from 'react';
import AuthLayout from '../../components/auth-layout';
import InstallerAuth from '../../components/installer-portal/auth/InstallerAuth';
import { featureFlagsContext } from '../../providers/FeatureFlagsProvider';
import { navigate } from 'gatsby';
import routes from '../../routes';
import useInstallerAuth from '../../hooks/pge-plus/useInstallerAuth';

export default function Auth() {
  const { isPGEPlusInstallerSignInEnabled, loading } = useContext(
    featureFlagsContext,
  );
  const { isAuthenticated } = useInstallerAuth();

  useEffect(() => {
    if (!loading) {
      if (isPGEPlusInstallerSignInEnabled) {
        if (isAuthenticated) {
          //If the user is already logged in, redirect to the job listing page
          void navigate(routes.PGE_PLUS_INSTALLER_PORTAL_JOB_LISTING);
        }
      } else {
        //Instaler portal is diabled
        void navigate(routes.HOME);
      }
    }
  }, [loading, isPGEPlusInstallerSignInEnabled]);

  if (loading || !isPGEPlusInstallerSignInEnabled) {
    return <></>;
  }

  return isAuthenticated ? null : (
    <AuthLayout footer={<></>} showLeft={false}>
      <InstallerAuth />
    </AuthLayout>
  );
}
