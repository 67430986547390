import React from 'react';
import { Grid } from '@material-ui/core';
import { styled, Theme } from '@material-ui/core/styles';
import useContentMgmt, {
  PageTextContentType,
} from '../../../hooks/useContentMgmt';

const PREFIX = 'InstallerLoginHint';

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled('div')(({ theme }: { theme: Theme }) => ({
  [`& .${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    // alignItems: 'center',
    width: '100%',
    '& ul li': {
      padding: '4px 8px',
      fontSize: theme.typography.body2.fontSize,
    },
  },
}));

type Props = {
  content: PageTextContentType;
};

export const InstallerLoginHint = ({ content }: Props) => {
  const { richText } = useContentMgmt();

  return (
    <Root>
      <Grid item xs={12} className={classes.root}>
        {richText(content.get('INSTALLER_LOGIN_HINT'))}
      </Grid>
    </Root>
  );
};

export default InstallerLoginHint;
