import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import isEmpty from 'lodash/isEmpty';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { BrowserNotice } from '../../../components/browser-check';
import { PlannedDownTimeWarning } from '../../../components/planned-downtime/PlannedDownTimeWarning';
import { useTranslation } from '../../../hooks/useTranslation';
import { featureFlagsContext } from '../../../providers/FeatureFlagsProvider';
import ROUTES from '../../../routes';
import colors from '../../../themes/main-colors';
import { useIsMobile } from '../../../util/style-utils';
import PGEButton from '../../buttons';
import EmailField from '../../email-text-field';
import PasswordField from '../../password-text-field';
import Link from '../../text-link';
import InstallerQuickLoginForm from './InstallerQuickLoginForm';
import { PageTextContentType } from '../../../hooks/useContentMgmt';
import useInstallerAuth from '../../../hooks/pge-plus/useInstallerAuth';
import SignInToggle from '../../sign-toggle/SignInToggle';

const useStyles = makeStyles(theme => ({
  submit: {
    backgroundColor: colors.orange,
    borderColor: colors.orange,
    '&:hover': {
      backgroundColor: colors.orange,
      borderColor: colors.orange,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      margin: '1em 0',
    },
  },
  signInBottom: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '.5em 0',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  links: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      marginTop: '-1em',
    },
  },
  plannedDownTimeWarningWrapper: {
    marginTop: '1rem',
  },
  checkbox: {
    '& .MuiFormControlLabel-label': {
      fontSize: '16px',
    },
  },
}));
export interface Props {
  content: PageTextContentType;
}

const InstallerSignInForm: FunctionComponent<Props> = (props: Props) => {
  const classes = useStyles();
  const { content } = props;
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [section, setSelection] = useState<string>('SignIn');
  const { isPlannedDownTime } = useContext(featureFlagsContext);

  const {
    form,
    handleLoginWithPassword,
    isSignInTokenLink,
  } = useInstallerAuth();

  useEffect(() => {
    if (isSignInTokenLink()) {
      setSelection('OneTimePay');
    }
  }, []);

  if (typeof window !== 'undefined' && !window.navigator.cookieEnabled) {
    return <BrowserNotice />;
  }

  return (
    <>
      {isPlannedDownTime ? (
        <div className={classes.plannedDownTimeWarningWrapper}>
          <PlannedDownTimeWarning />
        </div>
      ) : (
        <Grid container spacing={1} className={classes.container}>
          <Grid item>
            <SignInToggle section={section} setSection={setSelection} />
          </Grid>

          <Grid item>
            {section === 'SignIn' && (
              <form
                id={'login-form'}
                noValidate
                onSubmit={handleLoginWithPassword}
              >
                <Grid item>
                  <EmailField
                    autoFocus
                    autoComplete="username"
                    inputProps={{ maxLength: 50 }}
                    {...form.props('email')}
                  />
                </Grid>
                <Grid item>
                  <PasswordField
                    defaultShow={isMobile}
                    autoComplete="current-password"
                    {...form.props('password')}
                  />
                </Grid>
                <Grid item className={classes.links}>
                  <Grid item style={{ width: '100%' }}>
                    <Grid container className={classes.signInBottom}>
                      <Grid item>
                        <Link
                          plain
                          to={ROUTES.PGE_PLUS_INSTALLER_PORTAL_FORGOT_PASSWORD}
                          variant={'body2'}
                        >
                          {content.get('FORGOT_PASSWORD')}
                        </Link>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid container className={classes.signInBottom}>
                    <Grid item className={classes.checkbox}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={form.onChange}
                            checked={form.values.rememberMe}
                            name={'rememberMe'}
                            color={'primary'}
                          />
                        }
                        label={content.get('REMEMBER_ME')}
                      />
                    </Grid>
                    <Grid item className={classes.buttonContainer}>
                      <PGEButton
                        id="sign-in-submit-btn"
                        type={'submit'}
                        variant={'contained'}
                        color={'primary'}
                        disabled={!isEmpty(form.errors)}
                      >
                        {content.get('SIGN_IN')}
                      </PGEButton>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}

            {section === 'OneTimePay' && (
              <InstallerQuickLoginForm content={content} />
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default InstallerSignInForm;
