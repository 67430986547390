/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React, { FunctionComponent, useEffect, useState, Fragment } from 'react';
import { styled, Theme } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Button from '../../buttons/PGE-Button';
import EmailTextField from '../../email-text-field';
import InstallerLoginHint from './InstallerLoginHint';
import useInstallerAuth, {
  INSTALLER_SIGNIN_EMAIL_ID_STORAGE_KEY,
} from '../../../hooks/pge-plus/useInstallerAuth';
import useFormState, {
  convertValidationRules,
} from '../../../hooks/useFormState';
import { PageTextContentType } from '../../../hooks/useContentMgmt';
import { validateEmail } from '../../../util/form-validation';
import Backdrop from '../../backdrop';
import InstallerLoginStatusMessage from './InstallerLoginStatusMessage';
import Link from '../../text-link';

const PREFIX = 'InstallerQuickLogin';

const classes = {
  card: `${PREFIX}-card`,
  cardContent: `${PREFIX}-cardContent`,
  cardActions: `${PREFIX}-cardActions`,
  hr: `${PREFIX}-hr`,
  submit: `${PREFIX}-submit`,
  paidMessageBox: `${PREFIX}-paidMessageBox`,
};

const Root = styled('div')(({ theme }: { theme: Theme }) => ({
  [`& .${classes.card}`]: {},
  [`& .${classes.cardContent}`]: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
  [`& .${classes.cardActions}`]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: 0,
  },
  [`& .${classes.hr}`]: {
    border: '0.3px solid black',
    opacity: '0.1',
    margin: theme.spacing(3, 0),
  },
  [`& .${classes.submit}`]: {
    marginTop: 8,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  [`& .${classes.paidMessageBox}`]: {
    marginTop: '1em',
    '& > .MuiCollapse-container > .MuiCollapse-wrapper': {
      '& .MuiCollapse-wrapperInner > .MuiAlert-standardSuccess': {
        backgroundColor: '#fff !important',
      },
    },
  },
}));

type SignInFormType = {
  email: string;
};

type Props = {
  content: PageTextContentType;
};

const InstallerQuickLoginForm: FunctionComponent<Props> = ({ content }) => {
  const {
    errorMessage,
    isLoading,
    sendSignInLink,
    isSignInTokenLink,
    signInWithEmailLink,
    setErrorMessage,
  } = useInstallerAuth();
  const [
    isEmailConfirmationRequired,
    setIsEmailConfirmationRequired,
  ] = useState(false);

  const form = useFormState(
    { email: '' },
    {
      validate: createValidateFunction(),
    },
  );

  useEffect(() => {
    if (isSignInTokenLink()) {
      //User must enter thier email id to complete the sign in proccess if not found on the local storage
      const email =
        window.localStorage.getItem(INSTALLER_SIGNIN_EMAIL_ID_STORAGE_KEY) ||
        '';
      if (email) {
        void signInWithEmailLink(email);
      } else {
        setIsEmailConfirmationRequired(true);
      }
    }
  }, []);

  const handleSubmit = async (email: string) => {
    //Handle sending email and verifying the link of user has entered the email for confirmation
    if (isEmailConfirmationRequired) {
      void signInWithEmailLink(email);
    } else {
      await sendSignInLink(email);
      form.reset();
    }
  };

  const TITLE_INFO_TEXT = isEmailConfirmationRequired
    ? content.get('INSTALLER_SIGN_IN_CONFIRM_EMAIL_INFO')
    : content.get('INSTALLER_SIGN_IN_INFO');
  const BUTTON_TEXT = isEmailConfirmationRequired
    ? content.get('CONTINUE')
    : content.get('SUBMIT');

  return (
    <Root>
      <Fragment>
        <Typography component={'div'} variant={'body2'}>
          {TITLE_INFO_TEXT}
        </Typography>

        {errorMessage && (
          <Alert severity="error" css={{ marginTop: 8 }}>
            {errorMessage}
          </Alert>
        )}

        {isLoading && <Backdrop forceOpen />}

        <form noValidate>
          <EmailTextField
            name="email"
            label={content.get('REGISTERED_EMAIL_ADDRESS')}
            variant="outlined"
            autoComplete={'email'}
            style={{ width: '100%' }}
            {...form.props('email')}
          />
        </form>
        {!isEmailConfirmationRequired && (
          <InstallerLoginHint content={content} />
        )}
        <Box
          display="flex"
          justifyContent={
            isEmailConfirmationRequired ? 'space-between' : 'flex-end'
          }
          alignItems="center"
        >
          {isEmailConfirmationRequired && (
            <Link
              plain
              onClick={e => {
                e.preventDefault();
                setIsEmailConfirmationRequired(false);
                setErrorMessage('');
              }}
              variant={'body2'}
            >
              Request new signin link
            </Link>
          )}
          <Button
            className={classes.submit}
            type={'submit'}
            variant={'contained'}
            color={'primary'}
            onClick={form.submit(
              values => handleSubmit(form.values.email),
              console.log,
            )}
          >
            {BUTTON_TEXT}
          </Button>
        </Box>
      </Fragment>
    </Root>
  );
};

export default InstallerQuickLoginForm;

const createValidateFunction = () =>
  convertValidationRules<SignInFormType>(context => {
    const { values } = context;

    return {
      email: validateEmail,
    };
  });
