/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React, { FunctionComponent } from 'react';
import { styled, Theme } from '@material-ui/core/styles';
import { Typography, Card, CardContent } from '@material-ui/core';
import useContentMgmt from '../../../hooks/useContentMgmt';
import InstallerSignInForm from './InstallerSignInForm';
import theme from '../../../themes/theme';
import microcopyGroupIds from '../../pge-plus-common/microcopyGroupIds';

const PREFIX = 'InstallerLoginForm';

const classes = {
  card: `${PREFIX}-card`,
  cardContent: `${PREFIX}-cardContent`,
  cardActions: `${PREFIX}-cardActions`,
  hr: `${PREFIX}-hr`,
  submit: `${PREFIX}-submit`,
  paidMessageBox: `${PREFIX}-paidMessageBox`,
};

const Root = styled('div')(() => ({
  [`& .${classes.card}`]: {
    padding: '5em 6em',
    marginTop: '2em',
    [theme.breakpoints.down('sm')]: {
      padding: '4em 2em',
    },
  },
  [`& .${classes.cardContent}`]: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
}));

type Props = {};

const InstallerAuth: FunctionComponent<Props> = () => {
  const { content } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_INSTALLER_SIGN_IN,
  );

  return (
    <Root>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography variant={'h1'} css={{ marginBottom: theme.spacing(1) }}>
            {content.get('INSTALLER_LOGIN_TITILE')}
          </Typography>
          <InstallerSignInForm content={content} />
        </CardContent>
      </Card>
    </Root>
  );
};

export default InstallerAuth;
